import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { Storage} from '@ionic/storage';
import {tap, catchError} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoadingController , AlertController} from '@ionic/angular';





const USER_KEY = 'Bearer';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  username;
  email;
  token;
  role;
  authenticationState = new BehaviorSubject(false);

  constructor(private storage: Storage, private plt: Platform, private alert: AlertController, private loadingCtrl: LoadingController,
              private helper: JwtHelperService, private http: HttpClient) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  async presentAlert(info) {
    const alert = await this.alert.create({
      header: 'Login Error!!',
      message: info,
      buttons: ['OK']
    });

    await alert.present();
  }

    logout() {
      return this.storage.remove('login_info').then(() => {
        this.username = null;
        this.role = null;
        this.email = null;
        this.storage.remove(USER_KEY);
        this.authenticationState.next(false);
      });
    }

    isAuthenticated() {
      return this.authenticationState.value;
    }

    checkToken() {
      this.authenticationState.next(true);
      // this.storage.get('login_info').then(result => {
      //   if (result) {
      //     this.username = result.username;
      //     this.role = result.role;
      //     this.storage.get(USER_KEY).then(res => {
      //       if (res) {
      //         const isExpired = this.helper.isTokenExpired(res);
      //         if (isExpired) {
      //           this.authenticationState.next(false);
      //         } else {
      //           this.authenticationState.next(true);
      //         }
      //       } else {
      //         this.authenticationState.next(false);
      //       }
      //     });
      //   }
      // });
    }

    setUserinfo(user) {
      this.username = user.username;
      this.role = user.role;
    }

    login(un, pw) {
      // make sure you are using the correct appid from extrnaet dev instance
      // tslint:disable-next-line:max-line-length
      // const extraneturl: string = 'https://arcgis.dnr.state.mn.us/extranet/template/login_dev.py?' + 'username=' + un + '&password=' + pw + '&appid=111';
      // return this.http.get(extraneturl)
      // .pipe(
      //   tap(res => {
      //     // const decoded = this.helper.decodeToken(res.token);
      //     if (decoded.userinfo.status === 'SUCCESS') {
      //       debugger;
      //       if (decoded.userinfo.applications['GIS Template']) {
      //         if (decoded.userinfo.applications['GIS Template'].Administrator) {
      //           const userinfo = {
      //             username: decoded.userinfo.firstname + ' ' + decoded.userinfo.lastname,
      //             role: 'Administrator'
      //           };
      //           this.storage.set('login_info', userinfo);
      //           this.setUserinfo(userinfo);
      //         } else {
      //           const userinfo = {
      //             username: decoded.userinfo.firstname + ' ' + decoded.userinfo.lastname,
      //             role: 'Inspector'
      //           };
      //           this.storage.set('login_info', userinfo);
      //           this.setUserinfo(userinfo);
      //         }
      //       } else {
      //         const userinfo = {
      //           username: decoded.userinfo.firstname + ' ' + decoded.userinfo.lastname,
      //           role: 'Inspector'
      //         };
      //         debugger;
      //         this.storage.set('login_info', userinfo);
      //         this.setUserinfo(userinfo);
      //       }

      //       // this.storage.set(USER_KEY, res.token);
      //       this.loadingCtrl.dismiss();
      //       this.authenticationState.next(true);

      //     }
      //     if (decoded.userinfo.status === 'ERROR') {
      //       this.loadingCtrl.dismiss();
      //       this.presentAlert(decoded.userinfo.message);
      //     }

      //   }),
      //   catchError(e => {
      //     throw new Error(e);
      //   })
      // );
    }
  }


