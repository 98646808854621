import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Storage , IonicStorageModule} from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


//app imports
import { FormsModule } from '@angular/forms';


import { JwtModule, JWT_OPTIONS} from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

// import { ComboChartComponent, ComboSeriesVerticalComponent } from './main/components/combo-chart';

import { NgxEchartsModule } from 'ngx-echarts';





export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get('Bearer').then(res => {
        return res;
    })
  },
    whitelistedDomains: ['arcgis.dnr.state.mn.us:8082','arcgis.dnr.state.mn.us:8081']
  };
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage]
      }
    }),
    NgxUiLoaderModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
 ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
