import { Component} from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';





@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // @HostListener('window:beforeunload',['$event'])
  //   clearLocalStorage(event){
  //       localStorage.clear();
  //   }
  constructor(
    private platform: Platform,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.authService.authenticationState.subscribe(state => {
        if (state) {
          this.router.navigate(['main', 'historical']);
        }
     });
    });
  }
}
